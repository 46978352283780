@import '../../variables.scss';

.project-page {
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: rgb(117, 124, 130) 0px 0px 2px;    
  .top-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    height: 53px;
    .page-header {
      display: flex;
      align-items: flex-end;
      .btn-create-project {
        margin-left: 12px;
        .anticon-plus-circle {
          font-size: 22px;
        }
      }
    }
    
  }
  .page-content {
    flex: 1;
    padding: 20px;
    max-height: calc(100vh - 109px);
    overflow: hidden;
    overflow-y: auto;

    .loading-project {
      position: absolute;
      bottom: 80px;
      left: 45%;
    }
  }

  .project-list {
    .card-project {
      width: 264px;
      height: 292px;
      color: #6C737F; /* $defautl-font-color; */
      // background: rgb(255, 243, 243); /*$app-bg-box; */
      background: rgb(255, 255, 255);
      border-radius: $border-box-radius;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;    
      box-shadow: rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px;
      
      .ant-card-cover {
        width: 140px;
        display: flex;
        margin: 0 auto;
        flex: 1;
        align-items: center;
      }

      .ant-card-body {
        padding: 14px;
        .ant-card-meta {
          background: rgb(255, 255, 255);
          box-shadow: rgb(23, 185, 120) 0px 0px 2px;    
          padding: 0 22px;
          border-radius: $border-box-radius;
          height: 95px;
          display: flex;
          align-items: center;
          .ant-card-meta-detail {
            .ant-card-meta-title {
              color: rgb(23, 185, 120);
              font-weight: 580;
            }
            .ant-card-meta-description {
              color: rgb(23, 185, 120);
              font-weight: 580;
              font-size: 13px;
            }
          }
          .total-study {
            color: $defautl-font-color;
            margin-left: 6px;
            text-transform: lowercase;
            font-weight: 580;
          }
        }
      }
    }
  }
}
