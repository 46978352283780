@import '../../variables.scss';

.common-style-page .label-management-page {
  .top-content {
    align-items: center;
    .page-header {
      align-items: center;
      .title {
        padding: 8px;
      }
    }
  }
}
  
.label-management-page {
  display: flex;
  flex-direction: column;
  .page-content {
    flex: 1;
    padding: 8px;
    .col-labels {
      flex: 1;
      .labels-wrapper {
        padding: 8px 25px;
      }
    }
  }
}
