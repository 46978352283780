/* Variables */
$header-height: 40px;
$left-menu-width: 200px;
$left-menu-collapsed-width: 83px;
$border-box-radius: 15px;

/* app colors */
$defautl-font-color: #273F6B;
$app-bg-color: #151515;
$app-primary-color: #17b978;
$app-bg-box:  rgba(57, 63, 66, 0.5); /* #26292e; */
$app-bg-page-content: rgb(248, 248, 248);; /*rgb(20, 28, 35);*/
$app-box-border-color: #53555a;

$modal-bg-color: #242e4c;
$font-red-color: #f44336;
